import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/auth/Login'
import Admin from '../views/Admin'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'login',
		component: Login
	},
	{
		path: '/register',
		name: 'register',
		component: () => import('../views/auth/Register')
	},
	{
		path: '/forgot',
		name: 'forgot',
		component: () => import('../views/auth/Forgot')
	},
	{
		path: '/admin',
		name: 'admin',
		component: Admin,
		children: [{
				path: '/home',
				name: 'home',
				component: () => import('../views/Home'),
			},
			{
				path: '/order-list',
				name: 'order-list',
				component: () => import('../views/order/OrderList'),
			},
			{
				path: '/distri-order-history-list',
				name: 'distri-order-history-list',
				component: () => import('../views/order/DistriOrderHistoryList'),
			},
			{
				path: '/factory-order-history-list',
				name: 'factory-order-history-list',
				component: () => import('../views/order/FactoryOrderHistoryList'),
			},
			{
				path: '/return-list',
				name: 'return-list',
				component: () => import('../views/order/ReturnList'),
			},
			{
				path: '/cart-list',
				name: 'cart-list',
				component: () => import('../views/cart/CartList'),
			},
			{
				path: '/profile',
				name: 'profile',
				component: () => import('../views/auth/Profile'),
			}
		]
	},

	{
		path: '/*',
		redirect: '/dashboard'
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})


export default router